import request from '@/utils/request'

//  余额宝数据详情列表
export function yuebaoDetaileList(data) {
    return request({
        method: 'get',
        url: 'yuebao/incomes',
        params: data
    })
}

// 余额宝数据详情
export function yuebaoDetail() {
    return request({
        method: 'get',
        url: 'yuebao/balance'
    })
}

// 余额宝规则
export function yuebaoRule() {
    return request({
        method: 'get',
        url: 'yuebao/rule'
    })
}

// Roll out 
export function transferRollout(data) {
    return request({
        method: 'GET',
        url: 'yuebao/transfer/in',
        params: data ? data : null
    })
}

// Shift to
export function transferShiftto(data) {
    return request({
        method: 'GET',
        url: 'yuebao/transfer/out',
        params: data ? data : null
    })
}
